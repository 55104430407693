import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Chat from './chat';
import { auth, googleProvider } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithPopup } from 'firebase/auth';

function App() {
  const signIn = async (e) => {
    e.preventDefault();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('User email signed in:', result);
    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };

  const [user] = useAuthState(auth);

  const handleLogout = () => {
    auth.signOut();
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Home signIn={signIn} user={user} />}
        />
        <Route
          path="/chat"
          element={<Chat handleLogout={handleLogout} signIn={signIn} user={user} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
