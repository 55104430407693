import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBYLj7KGUl1OKU8MKzI2O3FKCx3kl6-Y7s",
    authDomain: "lawyer-f4557.firebaseapp.com",
    projectId: "lawyer-f4557",
    storageBucket: "lawyer-f4557.appspot.com",
    messagingSenderId: "178245948952",
    appId: "1:178245948952:web:5f454fa8708d5f7929156d",
    measurementId: "G-JLLFNJVM42"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();