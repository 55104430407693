import React from 'react';
import { Link } from 'react-router-dom';
import { Star, User, MessageSquare, Clock, Shield, HelpCircle, Edit3, CheckCircle } from 'lucide-react';

const Home = ({ signIn, user }) => {
    return (
        <div className="bg-gray-50 min-h-screen flex flex-col">
            {/* Header Section */}
            <header className="flex flex-col md:flex-row items-center justify-between max-w-7xl mx-auto mt-20 px-4 sm:px-12">
                <div className="md:w-1/2 space-y-6 text-center md:text-left">
                    <h1 className="text-5xl md:text-7xl font-extrabold text-gray-900 leading-tight">
                        Gen-AI al servicio de la justicia
                    </h1>
                    <p className="text-gray-700 text-lg">
                        Atena, brinda acceso a información jurídica del Perú en tiempo real y basada en documentos legales usando inteligencia artificial generativa 
                    </p>
                    <div className="flex space-x-4 justify-center md:justify-start">
                        <Link to={user ? "/chat" : "#"} onClick={!user ? signIn : null}>
                            <button className="bg-blue-600 text-white py-3 px-6 rounded-full font-semibold hover:bg-blue-700 transition">
                                Empezar
                            </button>
                        </Link>
                        <button className="bg-gray-200 text-gray-900 py-3 px-6 rounded-full font-semibold hover:bg-gray-300 transition">
                            Demo
                        </button>
                    </div>
                </div>
                <div className="mt-10 md:mt-0 md:w-1/2 flex justify-center md:justify-end">
                    {/* Adjusted image styling */}
                    <img src="https://maresone.s3.us-east-1.amazonaws.com/option.png" alt="Atena AI Chat"
                        className="w-full max-w-[90%] md:max-w-lg rounded-lg shadow-lg object-contain" />
                </div>
            </header>


            {/* Features Section */}
            <section id="features" className="mt-16 max-w-6xl mx-auto px-4">
                <h2 className="text-3xl font-bold pt-16 text-gray-900 text-center">Documentación legal en segundos</h2>
                <div className='mt-10 flex justify-center'>
                    <img src="https://maresone.s3.us-east-1.amazonaws.com/screenone.png" alt="Atena AI Chat"
                        className="w-3/4 rounded-lg object-contain" />
                </div>
                <div className="mt-10 grid pt-16 grid-cols-1 sm:grid-cols-3 gap-8">
                    <div className="bg-white rounded-lg p-6 shadow-md text-center">
                        <div className="bg-blue-100 text-gray-600 w-16 h-16 mx-auto flex items-center justify-center rounded-full mb-4">
                            <MessageSquare className="w-8 h-8" />
                        </div>
                        <h3 className="text-xl font-semibold">Acceso Gratuito</h3>
                        <p className="mt-2 text-gray-700">
                            Utiliza Atena de forma gratuita para resolver tus dudas legales sin costo alguno.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg p-6 shadow-md text-center">
                        <div className="bg-blue-100 text-gray-600 w-16 h-16 mx-auto flex items-center justify-center rounded-full mb-4">
                            <Clock className="w-8 h-8" />
                        </div>
                        <h3 className="text-xl font-semibold">Respuestas Inmediatas</h3>
                        <p className="mt-2 text-gray-700">
                            Obtén respuestas al instante sin largas esperas para citas o consultas.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg p-6 shadow-md text-center">
                        <div className="bg-blue-100 text-gray-600 w-16 h-16 mx-auto flex items-center justify-center rounded-full mb-4">
                            <Shield className="w-8 h-8" />
                        </div>
                        <h3 className="text-xl font-semibold">Fuentes Confiables</h3>
                        <p className="mt-2 text-gray-700">
                            Nuestra IA utiliza RAG para proporcionarte las fuentes de información precisas y confiables.
                        </p>
                    </div>
                </div>
            </section>

            {/* How It Works Section */}
            <section id="how-it-works" className="mt-16 max-w-6xl mx-auto px-4">
                <h2 className="text-3xl font-bold text-gray-900 text-center">Cómo Funciona</h2>
                <div className="mt-10 grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="bg-white rounded-lg p-6 shadow-md text-center">
                        <div className="bg-blue-100 text-gray-600 w-16 h-16 mx-auto flex items-center justify-center rounded-full mb-4">
                            <HelpCircle className="w-8 h-8" />
                        </div>
                        <h3 className="text-xl font-semibold">1. Haz tu Pregunta</h3>
                        <p className="mt-2 text-gray-700">
                            Escribe tu duda o consulta legal en nuestro chat.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg p-6 shadow-md text-center">
                        <div className="bg-blue-100 text-gray-600 w-16 h-16 mx-auto flex items-center justify-center rounded-full mb-4">
                            <Edit3 className="w-8 h-8" />
                        </div>
                        <h3 className="text-xl font-semibold">2. Procesamos tu Consulta</h3>
                        <p className="mt-2 text-gray-700">
                            Nuestra IA analiza tu pregunta utilizando inteligencia artificial y RAG.
                        </p>
                    </div>
                    <div className="bg-white rounded-lg p-6 shadow-md text-center">
                        <div className="bg-blue-100 text-gray-600 w-16 h-16 mx-auto flex items-center justify-center rounded-full mb-4">
                            <CheckCircle className="w-8 h-8" />
                        </div>
                        <h3 className="text-xl font-semibold">3. Recibe Respuesta y Fuentes</h3>
                        <p className="mt-2 text-gray-700">
                            Obtén una respuesta detallada junto con las fuentes de información.
                        </p>
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <div className="mt-16 flex justify-center">
                <Link to={user ? "/chat" : "#"} onClick={!user ? signIn : null}>
                    <button className="bg-blue-600 text-white py-3 px-8 rounded-full text-xl font-semibold hover:bg-blue-700 transition">
                        Comienza Ahora
                    </button>
                </Link>
            </div>

            {/* Footer */}
            <footer className="bg-gray-800 text-white mt-16 py-8">
                <div className="max-w-6xl mx-auto px-4">
                    <div className="flex flex-col md:flex-row justify-between">
                        <div className="mb-6 md:mb-0">
                            <p className="mt-2 text-gray-400">
                                Atena © {new Date().getFullYear()}. Todos los derechos reservados.
                            </p>
                        </div>
                        <div className="flex space-x-6">
                            <a href="/privacy" className="text-gray-400 hover:text-white">Política de Privacidad</a>
                            <a href="/terms" className="text-gray-400 hover:text-white">Términos de Uso</a>
                            <a href="/contact" className="text-gray-400 hover:text-white">Contacto</a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Home;
